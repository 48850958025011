<template>
  <router-link
    :to="routerUrl"
    class="co-pilot-button"
    :disabled="disabled"
  >
    <div class="d-flex align-items-center">
      <slot name="svg"></slot>
      <span>{{ buttonLabel }}</span>
    </div>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'ml-12 forward-arrow'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.706 10.709c.39-.39.39-1.025 0-1.416l-5-5a1.002 1.002 0 00-1.415 1.416l3.296 3.294H4a.999.999 0 100 2h9.584l-3.29 3.293a1.002 1.002 0 001.415 1.416l5-5-.003-.003z" fill="#AAABBA"/></svg>
  </router-link>
</template>

<script>
export default {
  name: "CoPilotButton",
  data () {
    return {
      url: "../../../assets/svgs/forward-arrow.svg"
    }
  },
  props: {
    routerUrl: {
      type: String,
      required: false,
      default: ""
    },
    buttonLabel: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.co-pilot-button {
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $slate06;
  color: $slate80;
  min-height: 56px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  div {
    width: calc(100% - 32px);
    span {
      width: calc(100% - 32px);
    }
  }
  .forward-arrow {
    transition: 0.3s ease-in;
    width: 20px;
  }
  &:hover {
    .forward-arrow {
      transform: translateX(4px);
    }
  }
}

.ml-12 {
  margin-left: 12px;
}

</style>
