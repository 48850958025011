<template>
  <div class="d-flex justify-content-center w-100 h-100 position-relative">
    <div class="co-pilot__home">
      <co-pilot-progress
        class="mb-4"
      />
      <h1 v-html="staticText.coPilotTitle"></h1>
      <p class="sub-heading">{{ staticText.helperText }}</p>
      <div>
        <co-pilot-button
          routerUrl="/co-pilot/create-playbook"
          :buttonLabel="staticText.createPlaybookLabel"
        >
          <template v-slot:svg>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-12'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4 1h5.625a.375.375 0 01.369.308l.006.067V7c0 .79.612 1.438 1.387 1.496l.113.004h5.625a.375.375 0 01.369.307l.006.068V17.5a1.5 1.5 0 01-1.387 1.496L16 19H4a1.5 1.5 0 01-1.496-1.387L2.5 17.5v-15c0-.79.612-1.438 1.388-1.496L4 1h5.625H4zm7.125 6V1.646a.113.113 0 01.164-.1l.028.021 5.616 5.616a.112.112 0 01.03.106.113.113 0 01-.075.08l-.035.006H11.5a.375.375 0 01-.369-.308L11.125 7V1.646 7z" fill="#555775"/></svg>
          </template>
        </co-pilot-button>

        <!-- <co-pilot-button
          :buttonLabel="staticText.analyzeAndImprovePlaybook"
          class="mb-3"
        >
          <template v-slot:svg>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-12'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.333 13.333l5-5.833 4.167 4.166 4.167-5" stroke="#555775" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.667 11.667a.833.833 0 101.667 0 .833.833 0 00-1.667 0zM7.5 7.5a.833.833 0 101.667 0 .833.833 0 00-1.667 0zM2.5 13.333a.833.833 0 101.667 0 .833.833 0 00-1.667 0zM15.833 6.667a.833.833 0 101.667 0 .833.833 0 00-1.667 0z" stroke="#555775" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </template>
        </co-pilot-button>

        <co-pilot-button
          :buttonLabel="staticText.analyzeAndEvaluateCalls"
          class="mb-3"
        >
          <template v-slot:svg>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-12'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.762.393a2.143 2.143 0 00-.783 1.036h-.836A2.143 2.143 0 003 3.57v14.286A2.143 2.143 0 005.143 20h10a2.143 2.143 0 002.143-2.143V3.571a2.143 2.143 0 00-2.143-2.142h-.836A2.142 2.142 0 0012.286 0H8c-.443 0-.876.137-1.238.393zm.733 1.245A.714.714 0 018 1.428h4.286a.714.714 0 110 1.43H8a.714.714 0 01-.505-1.22zM7.5 9.25a.75.75 0 01.75.75v4.167a.75.75 0 01-1.5 0V10a.75.75 0 01.75-.75zm3.25 4.083a.75.75 0 00-1.5 0v.834a.75.75 0 001.5 0v-.834zm1.75-2.416a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0v-2.5a.75.75 0 01.75-.75z" fill="#555775"/></svg>
          </template>
        </co-pilot-button>

        <co-pilot-button
          :buttonLabel="staticText.successfulConversationPlaybook"
        >
          <template v-slot:svg>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-12 magnifying-glass'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g filter="url(#filter0_d_1_3)"><path d="M16.674 12.533a6.767 6.767 0 001.95-4.798 6.767 6.767 0 00-2.017-4.77A6.828 6.828 0 0011.797 1a6.828 6.828 0 00-4.802 1.986A6.767 6.767 0 005 7.766a6.767 6.767 0 001.973 4.79 6.827 6.827 0 004.792 2.008 6.828 6.828 0 004.82-1.942l7.569 7.536m-4.052-5.187L25 19.848 23.842 21l-4.897-4.876" stroke="#555775" stroke-width="2" stroke-linecap="round" shape-rendering="crispEdges"/></g><defs><filter id="filter0_d_1_3" x="0" y="0" width="30.417" height="30.411" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1_3"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_1_3" result="shape"/></filter></defs></svg>
          </template>
        </co-pilot-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import CoPilotProgress from "./components/CoPilotProgress.vue"
import CoPilotButton from "./components/CoPilotButton.vue"

export default {
  name: "CoPilotHome",
  components: {
    CoPilotProgress,
    CoPilotButton
  },
  data () {
    return {
      staticTextDefault: {
        coPilotTitle: "Hi, I am your <br><span>bao Co-Pilot</span>!",
        helperText: "What can I do for you?",
        createPlaybookLabel: "Create a new playbook for me",
        analyzeAndImprovePlaybook: "Analyse and improve my current playbooks!",
        analyzeAndEvaluateCalls: "Analyse and evaluate the calls I have done so far!",
        successfulConversationPlaybook: "Create a playbook based on the successful conversation!"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style lang="scss" scoped>
.co-pilot {
  &__home {
    width: 100%;
    max-width: 498px;
    padding-top: 120px;

    h1 {
      line-height: 43.2px;
      font-size: 36px;
    }
    p.sub-heading {
      line-height: 21.6px;
      color: $slate80;
    }
  }
}

.mr-12 {
  margin-right: 12px;
}

.magnifying-glass {
  width: 20px;
  height: 20px;
}

</style>
